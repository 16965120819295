<template>
  <div class="row rest">
    <div class="rest aside-bg"></div>
    <WebOsTvApiSnb class="left-container" />
    <article class="right-container">
      <hgroup>
        <h1 id="TvEnroll">TV 등록</h1>
        <div class="note">
          TV를 제어 및 모니터링하기 위해 TV를 등록하는 과정입니다.
        </div>
      </hgroup>
      <h2>사용할 API</h2>
      <ul class="list-style-none">
        <li><router-link to="/docs/webos-tv/reference#tv-list">GET /webostv</router-link></li>
        <li><router-link to="/docs/webos-tv/reference#tv-enroll">POST /webostv/{ip}</router-link></li>
      </ul>
      <h2>Sequence</h2>
      <div>
        <ol>
          <li>
            TV를 등록하기 위해서는 TV와 같은 네트워크에 연결되어 있어야 합니다.
          </li>
          <li>
            TV 리스트 조회 API(GET /webostv)를 이용하여 등록할 수 있는 TV의
            리스트를 가져와야 합니다.
          </li>
          <li>
            등록할 TV의 ip를 확인한 후, TV 등록 API(POST /webostv/{ip})를
            호출하여 TV를 등록합니다.
          </li>
          <li>이 과정은 처음 1회만 수행하면 됩니다.</li>
        </ol>
      </div>
      <img src="/assets/img/webs1.png" alt="" style="margin-top: 48px;" />
      <hgroup>
        <h1 id="Command">TV 제어 및 모니터링</h1>
        <div class="note">TV를 제어 및 모니터링하는 과정입니다.</div>
      </hgroup>
      <h2>사용할 API</h2>
      <ul class="list-style-none">
        <li>
          <router-link to="/docs/webos-tv/reference#command"
            >POST /webostv/{ip}/command</router-link
          >
        </li>
        <li>
          <router-link to="/docs/webos-tv/reference#control"
            >POST /webostv/{ip}/control/*</router-link
          >
        </li>
        <li>
          <router-link to="/docs/webos-tv/reference#remote"
            >POST /webostv/{ip}/remote/*</router-link
          >
        </li>
        <li>
          <router-link to="/docs/webos-tv/reference#subscribe"
            >POST /webostv/{ip}/subscribe</router-link
          >
        </li>
      </ul>
      <h2>Sequence</h2>
      <div>
        <ol>
          <li>
            제어 및 모니터링 API/websocket를 호출하기 전에는 TV 등록이 되어
            있어야 합니다.
          </li>
          <li>이미 등록한 경우에는 다시 TV 등록을 하지 않아도 됩니다.</li>
          <li>
            TV 등록이 되어 있지 않다면, 제어 및 모니터링 API/websocket을 사용할
            때 에러가 발생합니다.
          </li>
          <li>
            제어 및 모니터링 API/websocket을 사용하기 위해서는 TV의 ip 주소가
            필요합니다. 따라서 TV 등록 시 얻은 ip 주소를 Partner 쪽에서 가지고
            있어야 합니다.
          </li>
        </ol>
        <img src="/assets/img/webs2.png" alt="" style="margin-top: 49px;" />
      </div>
    </article>
    <div class="rest"></div>
  </div>
</template>
<script>
import WebOsTvApiSnb from "./WebOsTvApiSnb.vue";
export default {
  name: "webos-tv-api",
  components: { WebOsTvApiSnb },
};
</script>
